setTimeout(function() {
  if (!window.UI8) return;
  
  window.UI8.softwareFilters = [
    {
      name: "Any Platform",
      slug: "any-prog",
      alt_name: "Any Software"
    }, {
      name: "Sketch",
      slug: "sketch-prog"
    }, {
      name: "XD",
      slug: "xd-prog"
    }, {
      name: "Figma",
      slug: "figma-prog"
    }, {
      name: "Framer",
      slug: "framer-prog"
    }, {
      name: "Photoshop",
      slug: "ps-prog"
    }, {
      name: "Illustrator",
      slug: "ai-prog"
    }, {
      name: "After Effects",
      slug: "ae-prog"
    }, {
      name: "Keynote",
      slug: "keynote-prog"
    }, {
      name: "PowerPoint",
      slug: "powerpoint-prog"
    }
  ];
  
  window.UI8.categoryFilters = [
    {
      name: "All Products",
      slug: "all"
    }, {
      name: "UI Kits",
      slug: "ui-kits"
    }, {
      name: "Wireframe Kits",
      slug: "wireframe-kits"
    }, {
      name: "Icons",
      slug: "icons"
    }, {
      name: "Fonts",
      slug: "fonts"
    }, {
      name: "Presentation",
      slug: "presentation"
    }, {
      name: "Themes",
      slug: "themes"
    }, {
      name: "Freebies",
      slug: "freebies"
    }
  ];
}, 1);